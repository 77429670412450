<template>
  <div class="divider-line"></div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
  @import '../../assets/styleVars.scss';

  .divider-line {
    width: 100%;
    height: 1px;
    background-color: $mainBackgroundColor;
  }
</style>